import store from "@/store";
import HttpService from "./HttpService";
import localstore_sportevent from "../store/localstore_sportevent.module";
import localstore_competitions from "../store/localstore_competitions.module";
import localstore_starters from "../store/localstore_starters.module";
import localstore_persons from "../store/localstore_persons.module";
import localstore_societies from "../store/localstore_societies.module";
import localstore_startgroups from "../store/localstore_startgroups.module";
import localstore_sporteventcompetitions from "../store/localstore_sporteventcompetitions.module";

export default class BaseService<T> {
  public serviceUrl: string;
  public localStorageName: string;
  public localStorage: any;

  constructor(serviceUrl: string, localstoreName = "") {
    this.serviceUrl = serviceUrl;
    this.localStorageName = localstoreName;
  }

  initLocalStorage(name: string): any {
    if (name !== "") {
      switch (name) {
        case "localstore_persons": {
          return localstore_persons;
        }
        case "localstore_societies": {
          return localstore_societies;
        }
        case "localstore_sportevent": {
          return localstore_sportevent;
        }
        case "localstore_competitions": {
          return localstore_competitions;
        }
        case "localstore_starters": {
          return localstore_starters;
        }
        case "localstore_startgroups": {
          return localstore_startgroups;
        }
        case "localstore_sporteventcompetitions": {
          return localstore_sporteventcompetitions;
        }
      }
    }
  }

  async getAll(reload = false): Promise<T[]> {
    if (this.localStorageName !== "") {
      let items: T[] = [];
      this.localStorage = this.initLocalStorage(this.localStorageName);
      if (this.localStorage === null || 
          this.localStorage.state === null ||
          this.localStorage.state.entries === null || 
          this.localStorage.state.entries.length === 0 || 
          reload ) {
        await store.dispatch(
          `${this.localStorageName}/setAll`,
          await this.getAllFromApi()
        );
      }
      items = this.localStorage.state.entries as T[];
      //console.log("found", items);
      return items as T[];
    }
    return await this.getAllFromApi() as T[];
  }

  async get(id: string): Promise<T> {
    if (this.localStorageName !== "") {
      try{
        this.localStorage = this.initLocalStorage(this.localStorageName);
        const item = this.localStorage.state.entries.find(
          (i: any) => i.id === id
        );
        if (item !== undefined) {
          return item as T;
        }
      } catch{
        
      }
    }

    const item = await this.getFromApi(id);
    if (this.localStorageName !== "") {
      await store.dispatch(`${this.localStorageName}/add`, item);
    }
    return item;
  }

  async save(item: any): Promise<T> {
    if (item.id === undefined) {
      const itemFromRequest = await this.createOnApi(item.id, item);
      if (this.localStorageName !== "") {
        await store.dispatch(`${this.localStorageName}/add`, itemFromRequest);
      }
      return itemFromRequest;
    } else {
      const itemFromRequest = await this.updateOnApi(item.id, item);
      if (this.localStorageName !== "") {
        await store.dispatch(
          `${this.localStorageName}/update`,
          itemFromRequest
        );
      }
      return itemFromRequest as T;
    }
  }

  async delete(id: string): Promise<void> {
    if (id !== "") {
      await this.deleteOnApi(id);
      if (this.localStorageName !== "") {
        await store.dispatch(`${this.localStorageName}/delete`, id);
      }
    }
  }

  // FROM API
  async getAllFromApi(urlAddOn = ""): Promise<T[]> {
    const response = await HttpService.get<T[]>(
      `${this.serviceUrl}${urlAddOn}`
    );
    return response as T[];
  }
  async getFromApi(id: string): Promise<T> {
    const response = await HttpService.get<T>(`${this.serviceUrl}${id}/`);
    return response as T;
  }
  async createOnApi(id: string, entry: T): Promise<T> {
    const response = await HttpService.post<T>(this.serviceUrl, entry);
    return response as T;
  }
  async updateOnApi(id: string, entry: T): Promise<T> {
    const response = await HttpService.put<T>(
      `${this.serviceUrl}${id}/`,
      entry
    );
    return response as T;
  }
  async deleteOnApi(id: string): Promise<T> {
    const response = await HttpService.delete<T>(`${this.serviceUrl}${id}/`);
    return response;
  }
}
