
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import i18n from "../../plugins/i18n";
import draggable from "vuedraggable";

import formatDate from "../../plugins/formatDate";
import StartGroupService from "../../services/sportevent/StartGroupService";
import StartGroup from "../../models/sportevent/StartGroup";
import StartBlock from "../../models/sportevent/StartBlock";
import ToastService from "../../services/helper/ToastService";
import Sportevent from "../../models/sportevent/Sportevent";

@Component({
  components: {
    draggable,
  },
  filters: {
    formatDate,
  },
})
export default class StartGroupsComponent extends Vue {
  @Prop({ required: true })
  public startBlock!: StartBlock;

  // Loading
  public loading = true;
  // Table
  public startGroups: StartGroup[] = [];

  public dragOptions = {
    animation: 200,
    group: "description",
    disabled: false,
    ghostClass: "ghost",
  };

  async mounted(): Promise<void> {
    this.getAll();
  }

  async getAll(): Promise<void> {
    this.startGroups = await StartGroupService.getAll();

    if (this.startGroups != undefined && this.startGroups.length > 0) {
      this.startGroups = this.startGroups.filter(
        (sb: StartGroup) => sb.startBlock.id === this.startBlock.id
      );

      this.startGroups = this.startGroups.map((sg) => new StartGroup(sg));
      this.startGroups.sort((a, b) =>
        a.startGroupPosition > b.startGroupPosition ? 1 : -1
      );
      this.startGroups.forEach((startgroup: StartGroup) => {
        startgroup.starters = startgroup.starters.sort((a, b) =>
          a.positionInStartgroup > b.positionInStartgroup ? 1 : -1
        );
      });
    }
    this.loading = false;
  }

  deleteStartGroup(startGroup: StartGroup): void {
    this.loading = true;
    if (startGroup.id != undefined) {
      if (confirm(i18n.tc("labels.confirmDelete"))) {
        StartGroupService.delete(startGroup.id).then(() => {
          this.getAll();
        });
      }
    }
  }

  StartGroupName(): void {
    return this.$store.state.localstore_sportevent.sportevent.startgroupName
      .name;
  }

  getAllCompetitionName(startGroup: StartGroup): string {
    return startGroup.sporteventCompetitions
      .map((e) => e.competition.name)
      .join(", ");
  }

  getAllStarterName(startGroup: StartGroup): string {
    return startGroup.starters
      .map((e) => `${e.person.firstname} ${e.person.lastname}`)
      .join(", ");
  }

  getAllSociety(startGroup: StartGroup): string {
    let societies = [
      ...new Set(startGroup.starters.map((s) => s.startsForSociety)),
    ];

    let societies_sn = [...new Set(societies.map((s) => s.shortname))];
    return societies_sn.join(", ");
  }

  async updateStartgroups(silent = false): Promise<void> {
    if (
      this.startBlock !== null &&
      this.startBlock.automaticStartGroupPosition
    ) {
      //console.log("update Startgroups");
      let position = this.startBlock.startGroupPositionBeginn;
      const updateNames = async () => {
        this.startGroups.forEach((startGroup) => {
          startGroup.startGroupPosition = position;
          position++;
          StartGroupService.save(startGroup);
        });
      };
      updateNames().then(() => {
        if (!silent) {
          ToastService.Success(i18n.tc("labels.saved"), "");
        }
      });
    }
  }

  @Watch("$route.query.editedStartgroupId")
  async startgroupedited() {
    const editedStartgroupId = this.$route.query.editedStartgroupId.toString();
    if (editedStartgroupId !== undefined && editedStartgroupId !== null) {
      await this.getAll();
      console.log(this.$route.query.isNew);
      if (
        this.$route.query.isNew !== null &&
        this.$route.query.isNew === "true"
      ) {
        // new

        const newStartgroup = new StartGroup(
          await StartGroupService.get(editedStartgroupId)
        );
        if (newStartgroup.startBlock.id === this.startBlock.id) {
          console.log("new", newStartgroup);
          let indexEditedStartgroup = this.startGroups.findIndex(
            (startgroup) => startgroup.id == newStartgroup.id
          );
          if (indexEditedStartgroup == -1) {
            this.startGroups.push(newStartgroup);
          } else {
            this.startGroups.splice(indexEditedStartgroup, 1, newStartgroup);
          }
        }
      } else {
        // update
        let indexEditedStartgroup = this.startGroups.findIndex(
          (startgroup) => startgroup.id == this.$route.query.editedStartgroupId
        );

        if (indexEditedStartgroup != -1) {
          const updatedStartgroup = new StartGroup(
            await StartGroupService.get(editedStartgroupId)
          );

          if (
            null != updatedStartgroup.id &&
            updatedStartgroup.startBlock.id === this.startBlock.id
          ) {
            console.log(
              "found update for ",
              this.startGroups[indexEditedStartgroup]
            );
            this.startGroups.splice(
              indexEditedStartgroup,
              1,
              updatedStartgroup
            );
          }
        }
      }
    }
  }
}
