import HttpService from "../HttpService";
import StartGroup from "@/models/sportevent/StartGroup";
import store from "../../store/index";
import localstore_sportevent from "../../store/localstore_sportevent.module";
import localstore_startgroups from "../../store/localstore_startgroups.module";
import BaseService from "../BaseService";
import Discipline from "@/models/sporteventCalculation/Discipline";
import SporteventCompetitionService from "./SporteventCompetitionService";
import Sportevent from "@/models/sportevent/Sportevent";
import SporteventCompetition from "@/models/sportevent/SporteventCompetition";
import DisciplineCategory from "@/models/sporteventCalculation/DisciplineCategory";

class StartGroupService extends BaseService<StartGroup> {
  public url: string;

  constructor(url = "sportevent/startgroup/") {
    super(url, "localstore_startgroups");
    this.url = url;
  }

  async getAllBySportevent(reload = false): Promise<StartGroup[]> {
    if (
      (localstore_sportevent.state.sportevent.id !== null &&
        localstore_startgroups.state.entries === null) ||
      reload
    ) {
      const sg = await this.getAllBySporteventWithApi(
        localstore_sportevent.state.sportevent.id
      );
      await store.dispatch("localstore_startgroups/setAll", sg);
    }
    if (localstore_startgroups.state.entries != undefined) {
      const startgroups = localstore_startgroups.state.entries;
      return startgroups.map((s: StartGroup) => new StartGroup(s));
    }
    return [];
  }

  async getAllBySporteventWithApi(id: string): Promise<StartGroup[]> {
    const response = await HttpService.get<StartGroup[]>(
      `${this.url}bysportevent/${id}/`
    );
    if (response !== undefined) {
      response.map((s: StartGroup | undefined) => new StartGroup(s));
      this.sortStartGroups(response);
    }
    if (response !== undefined) return response;
    return [];
  }

  async getAllByStartBlock(id: string) {
    const response = await HttpService.get<StartGroup[]>(
      `${this.url}bystartblock/${id}/`
    );
    if (response !== undefined) return this.sortStartGroups(response);
    return [];
  }

  sortStartGroups(startgroups: StartGroup[]): StartGroup[] {
    startgroups.sort((a, b) =>
      a.startGroupPosition > b.startGroupPosition ? 1 : -1
    );
    startgroups.forEach((startgroup: StartGroup) => {
      startgroup.starters.sort((a, b) =>
        a.positionInStartgroup > b.positionInStartgroup ? 1 : -1
      );
    });
    return startgroups;
  }

  async getDisciplinesFromStartGroup(startgroup: StartGroup, scs: SporteventCompetition[]): Promise<Discipline[]> {
    let disciplines: Discipline[] = [];
    startgroup.sporteventCompetitions.forEach( (sc: SporteventCompetition) => {
      let tmp = scs.find( (s: SporteventCompetition ) => s.id === sc.id);
      if(tmp !== undefined){
        tmp.disciplines.forEach( (discipline : Discipline) =>{
          let index = disciplines.findIndex(d => d.id === discipline.id)
          if(index === -1){
            disciplines.push(discipline);
          }
        });
      }
    });
    return disciplines;
  }
  
}

export default new StartGroupService();
