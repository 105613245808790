import Sportevent from "./Sportevent";
import moment from "moment";

export default class StartBlock {
  id?: string;
  name = "Durchgang";
  sportevent: Sportevent = new Sportevent();
  startTime: Date = new Date();
  endTime: Date = new Date();
  startBlockPosition = 1;
  startBlockPositionAsPrefixInStartgroupName = true;
  startGroupPositionBeginn = 1;
  automaticStartGroupPosition = true;

  constructor(startBlock?: StartBlock) {
    if (startBlock === undefined || startBlock === null) {
      return;
    }
    this.id = startBlock.id;
    this.name = startBlock.name;
    this.sportevent = startBlock.sportevent;
    this.startTime = startBlock.startTime;
    this.endTime = startBlock.endTime;
    this.startBlockPosition = startBlock.startBlockPosition;
    this.startBlockPositionAsPrefixInStartgroupName =
      startBlock.startBlockPositionAsPrefixInStartgroupName;
    this.startGroupPositionBeginn = startBlock.startGroupPositionBeginn;
    this.automaticStartGroupPosition = startBlock.automaticStartGroupPosition;
  }

  getDay(value: Date): string {
    return moment(String(value)).format("DD.MM.YYYY");
  }

  getTime(value: Date): string {
    return moment(String(value)).format("HH:mm");
  }

  public get getName(): string {
    let name = "";
    if (this.startBlockPosition > 0) {
      name += `${this.startBlockPosition}. `;
    }
    if (this.name != "") {
      name += `${this.name} `;
    }
    if (
      this.startBlockPosition > 0 &&
      this.getDay(this.startTime) != this.getDay(this.endTime) &&
      this.getDay(this.startTime) != this.getDay(this.sportevent.eventDate)
    ) {
      name += `${moment(String(this.startTime)).format(
        "DD.MM.YYYY HH:mm"
      )} - ${moment(String(this.endTime)).format("DD.MM.YYYY HH:mm")}`;
    } else {
      if (this.startBlockPosition > 0) {
        name += `${this.getTime(this.startTime)} - ${this.getTime(
          this.endTime
        )}`;
      }
    }

    return name;
  }
}
