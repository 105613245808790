import Sportevent from "./Sportevent";
import StartBlock from "./StartBlock";
import SporteventCompetition from "./SporteventCompetition";
import Starter from "./Starter";

export default class StartGroup {
  id?: string;
  sportevent: Sportevent = new Sportevent();
  startBlock: StartBlock = new StartBlock();
  sporteventCompetitions: SporteventCompetition[] = [];
  starters: Starter[] = [];
  startGroupPosition = 0;
  startnumberMin = 1;

  constructor(startGroup?: StartGroup) {
    if (startGroup === undefined || startGroup === null) {
      return;
    }
    this.id = startGroup.id;
    this.sportevent = startGroup.sportevent;
    this.startBlock = startGroup.startBlock;
    this.sporteventCompetitions = startGroup.sporteventCompetitions;
    this.starters = startGroup.starters;
    this.startGroupPosition = startGroup.startGroupPosition;
    this.startnumberMin = startGroup.startnumberMin;
  }

  public get StartGroupName(): string {
    const startGroupNumber = String(this.startGroupPosition).padStart(2, "0");
    if (this.startBlock.startBlockPositionAsPrefixInStartgroupName) {
      return `${this.startBlock.sportevent.startgroupName.name} ${this.startBlock.startBlockPosition}${startGroupNumber}`;
    } else {
      return `${this.startBlock.sportevent.startgroupName.name} ${startGroupNumber}`;
    }
  }

  public get Competitions(): string {
    const startGroupNumber = String(this.startGroupPosition).padStart(2, "0");
    if (this.startBlock.startBlockPositionAsPrefixInStartgroupName) {
      return `${this.startBlock.sportevent.startgroupName.name} ${this.startBlock.startBlockPosition}${startGroupNumber}`;
    } else {
      return `${this.startBlock.sportevent.startgroupName.name} ${startGroupNumber}`;
    }
  }
}
