
import { Component, Vue, Watch } from "vue-property-decorator";
import i18n from "../../plugins/i18n";
import moment from "moment";

import formatDate from "../../plugins/formatDate";

import StartBlockService from "../../services/sportevent/StartBlockService";
import StartBlock from "../../models/sportevent/StartBlock";
import StartGroupsComponent from "./StartGroups.vue";
import Starter from "../../models/sportevent/Starter";
import StarterService from "../../services/sportevent/StarterService";
import StartGroup from "../../models/sportevent/StartGroup";
import StartGroupService from "../../services/sportevent/StartGroupService";
import SporteventService from "../../services/sportevent/SporteventService";
import Sportevent from "@/models/sportevent/Sportevent";

@Component({
  components: {
    StartGroupsComponent,
  },
  filters: {
    formatDate,
  },
})
export default class StartBlocksComponent extends Vue {
  public startersWithoutStartgroup: Starter[] = [];
  public startersWithoutStartgroupFields = [
    {
      key: "person",
      sortable: true,
      label: i18n.tc("labels.name"),
    },
    {
      key: "startsForSociety",
      sortable: true,
      label: i18n.tc("societymgmt.society"),
    },
    {
      key: "sporteventCompetition",
      sortable: true,
      label: i18n.tc("sporteventCalculation.competition"),
    },
  ];

  // Loading
  public loading = true;
  // Table
  public items: StartBlock[] = [];
  public fields = [
    {
      key: "actions",
      sortable: false,
      label: "",
    },
  ];
  public filter = "";
  public startgroups: StartGroup[] = [];
  public starters: Starter[] = [];
  public sportevent: Sportevent = new Sportevent();

  async mounted(): Promise<void> {
    this.getAll();
  }

  async getAll(): Promise<void> {
    this.sportevent = SporteventService.get();
    if (this.sportevent.id != undefined) {
      this.loading = true;
      this.startgroups = await StartGroupService.getAllBySportevent();
      if (
        this.startgroups === null ||
        this.startgroups === undefined ||
        this.startgroups.length === 0
      ) {
        this.startgroups = await StartGroupService.getAllBySportevent(true);
      }

      this.items = await StartBlockService.getAllBySportevent(
        this.sportevent.id
      );

      if (this.items.length > 0) {
        this.starters = await StarterService.getAll();
        await this.getStarterWithoutStartgroup();
        this.items = this.items.sort((a, b) =>
          a.startBlockPosition > b.startBlockPosition ? 1 : -1
        );
      }
      this.loading = false;
    }
  }

  @Watch("$route", { immediate: true, deep: true })
  async onUrlChange(): Promise<void> {
    const sporteventId = this.sportevent.id;
    if (sporteventId !== null && sporteventId !== undefined) {
      this.items = await StartBlockService.getAllBySportevent(sporteventId);
    }
  }

  async reloadStartgroups(): Promise<void> {
    this.loading = true;
    await StartGroupService.getAllBySportevent(true);
    this.getAll();
  }

  deleteStartBlock(startBlock: StartBlock): void {
    this.loading = true;
    if (startBlock != undefined && startBlock.id != undefined) {
      if (confirm(i18n.tc("labels.confirmDelete"))) {
        StartBlockService.delete(startBlock.id).then(() => {
          this.getAll();
        });
      }
    }
  }

  getDay(value: Date): string {
    return moment(String(value)).format("DD.MM.YYYY");
  }

  getTime(value: Date): string {
    return moment(String(value)).format("HH:mm");
  }

  StartGroupName(): string {
    return this.$store.state.localstore_sportevent.sportevent.startgroupName
      .name;
  }

  async getStarterWithoutStartgroup(): Promise<void> {
    this.startersWithoutStartgroup = (
      await StarterService.getAllBySporteventWithoutStartgroup(
        this.$store.state.localstore_sportevent.sportevent.id
      )
    ).sort((a, b) =>
      a.sporteventCompetition.competition.name >
      b.sporteventCompetition.competition.name
        ? 1
        : -1
    );
  }

  CustomFilterTable(row: StartBlock, filter: string): boolean {
    if (filter.length < 2) {
      return true;
    }
    filter = filter.toLowerCase();
    row = new StartBlock(row);
    let foundStarter = false;
    const startgroupsThisStartBlock = this.startgroups.filter(
      (s) => s.startBlock.id === row.id
    );
    //console.log("startgroupsThisStartBlock", startgroupsThisStartBlock);
    startgroupsThisStartBlock.forEach((sg: StartGroup) => {
      const startGroupStarters = sg.starters
        .map((e) => `${e.person.firstname} ${e.person.lastname}`)
        .join(" ")
        .toLowerCase();
      //console.log("startGroupStarters", startGroupStarters);
      if (startGroupStarters.includes(filter)) {
        foundStarter = true;
      }
    });
    //console.log("search", foundStarter);

    if (foundStarter) {
      return true;
    } else {
      return false;
    }
  }

  async createStartgroup(startBlockId: string): Promise<void> {
    const count = (await StartGroupService.getAll()).filter(
      (sb: StartGroup) => sb.startBlock.id === startBlockId
    ).length;

    this.$router.replace(
      `/sportevent/startblocks/${startBlockId}/startgroup/new/?startgroupcount=${count}`
    );
  }
}
