import HttpService from "../HttpService";
import StartBlock from "@/models/sportevent/StartBlock";

import BaseService from "../BaseService";

class StartBlockService extends BaseService<StartBlock> {
  public url: string;
  constructor(url = "sportevent/startblock/") {
    super(url, "");
    this.url = url;
  }

  async getAllBySportevent(id: string): Promise<StartBlock[]> {
    const response = await HttpService.get<StartBlock[]>(
      `${this.url}bysportevent/${id}/`
    );
    if (response != undefined) {
      return response.map((sb: StartBlock) => new StartBlock(sb));
    }
    return [];
  }
}

export default new StartBlockService();
